<template>
  <v-sheet height="100%" color="white">
    <!--<v-app-bar app  v-if="$vuetify.breakpoint.smAndDown">
      <v-btn icon @click.stop="menu = !menu">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <div class="d-flex align-center text-h5 ml-1">
        <img width="220" src="@/assets/text_econsenso.png" />
      </div>

      <v-spacer />

    </v-app-bar>-->

    <v-sheet color="transparent" min-height="100%" class="pa-2 d-flex">
      <div
        v-if="$vuetify.breakpoint.lgAndUp"
        class="d-flex flex-column pb-4"
        :style="{
          position: 'fixed',
          height: `${contentHeight}px`
        }"
      >
        <v-card
          elevation="4" width="250px" height="100%"
          class="flex-grow-1"
          style="overflow: hidden"
        >
          <Menu />
        </v-card>
      </div>
      <div class="flex-grow-1" :style="{'margin-left': $vuetify.breakpoint.lgAndUp ? '250px':'0px'}">
        <v-sheet color="transparent" class="d-flex flex-column" height="100%">
          <router-view
            class="flex-grow-1"
            :class="{ 'ml-2': $vuetify.breakpoint.lgAndUp}"
            :contentHeight="contentHeight"
          />
        </v-sheet>
      </div>
    </v-sheet>
    
  </v-sheet>
</template>

<script>
import Menu from "@/components/Menu.vue";

export default {
  name: "Main",

  components: { Menu },

  computed: {
    contentHeight(){
      return this.$vuetify.breakpoint.height - 64 /*app bar*/
    },
  },

  data: () => ({
    menu: false,
    consentDialog: false,
  }),

  created(){
    if (!this.$store.state.logged){
      this.$router.replace({name: 'login'})
    }
  },
};
</script>
